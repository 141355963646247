import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/video-streaming-developer-docs/video-streaming-developer-docs/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const InlineNotification = makeShortcode("InlineNotification");
const Title = makeShortcode("Title");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <InlineNotification kind="warning" mdxType="InlineNotification">
      <p><strong parentName="p">{`Deprecation Warning:`}</strong>{` This API is deprecated and it is scheduled to be removed on 03/01/2022. Please use (or migrate to) the new version of the `}<a parentName="p" {...{
          "href": "/viewer-authentication-api-v2-getting-started"
        }}>{`Viewer Authentication`}</a>{`.`}</p>
    </InlineNotification>
    <h2>{`Channel level`}</h2>
    <pre><code parentName="pre" {...{}}>{`PUT https://api.video.ibm.com/channels/CHANNEL_ID/locks/hash/advanced.json
`}</code></pre>
    <Title mdxType="Title">Parameters</Title>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`PARAMETER`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`TYPE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`IMPORTANCE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`DESCRIPTION`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`url`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`REQUIRED`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`URL of the authentication flow entry point`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`secret`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`REQUIRED`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Secret key`}</td>
        </tr>
      </tbody>
    </table>
    <Title mdxType="Title">Response</Title>
    <p>{`HTTP response codes returned when the action is successful:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`HTTP RESPONSE CODE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`DESCRIPTION`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`201 Created`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Viewer authentication has been set up on the channel with the new hash.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`204 No Content`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Viewer authentication has been set up on the channel by updating the existing hash with the new one.`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`Specific error codes returned when the action failed:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`ERROR VALUE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`HTTP RESPONSE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`CODE DESCRIPTION`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`invalid_request`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`400 Bad Request`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`One or more required parameters are missing (secret, url).`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`invalid_type`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`400 Bad Request`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The URL is invalid.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`lack_of_ownership`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`403 Forbidden`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The api user is not allowed to manage the given channel`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`not_found`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`404 Not found`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Channel not found`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Video level`}</h2>
    <p>{`By default videos inherit the authentication settings from channels but it can be overridden on a video by video basis.`}</p>
    <h3>{`Set up viewer authentication for a video`}</h3>
    <pre><code parentName="pre" {...{}}>{`PUT https://api.video.ibm.com/videos/VIDEO_ID/locks/hash.json
`}</code></pre>
    <Title mdxType="Title">Parameters</Title>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`PARAMETER`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`TYPE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`IMPORTANCE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`DESCRIPTION`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`url`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`REQUIRED`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`URL of the authentication flow entry point`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`secret`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`REQUIRED`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Secret key`}</td>
        </tr>
      </tbody>
    </table>
    <Title mdxType="Title">Response</Title>
    <p>{`HTTP response code returned when the action is successful:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`HTTP RESPONSE CODE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`DESCRIPTION`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`204 No content`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Viewer authentication has been set up on the video`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Implementing the authentication response`}</h2>
    <p>{`If the authentication was successful, your service must pass an authentication response to the player. The authentication response must be a JSON encoded array which contains the following:`}</p>
    <ul>
      <li parentName="ul">{`list of parameters that have been hashed`}</li>
      <li parentName="ul">{`the MD5 hash of the string that has been created by concatenating the parameters and the shared secret key with pipe ”|” characters`}</li>
    </ul>
    <p>{`Example script in PHP for creating the authentication response when the authentication was successful`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// We create the array of parameters.
// The parameters can be anything.
$userData = [
    "user" => $_SESSION["user"],
    "someString" => "someValue"
];

// We create the hash by hashing
// the parameters array contents and
// the secret key
// concatenated together with a pipe character.
$hash = md5(implode('|', $userData) . '|' . $SHARED_SECRET_KEY);

// We put the array of parameters and the hash into the response.
$response = array_chunk(
    array_merge($userData, ["hash" => $hash]),
    1,
    true
);

// We encode the response into JSON format.
$json = json_encode($response);
`}</code></pre>
    <InlineNotification kind="info" mdxType="InlineNotification">
  The order of the parameters should match their order in the array when creating the hash.
    </InlineNotification>
    <p>{`To pass the response to the player your service must redirect the page to the IBM Video Streaming return URL with passing the response in JSON format as a get parameter. You can test the API response at the `}<a parentName="p" {...{
        "href": "/viewer-authentication-api-hash-authentication-test"
      }}>{`Hash Authentication Test`}</a>{` page.`}</p>
    <pre><code parentName="pre" {...{}}>{`https://video.ibm.com/embed/hashlock/pass?hash=RESPONSE_IN_JSON_FORMAT
`}</code></pre>
    <h2>{`Managing viewer authentication settings`}</h2>
    <h3>{`Getting channel viewer authentication settings`}</h3>
    <pre><code parentName="pre" {...{}}>{`GET https://api.video.ibm.com/channels/CHANNEL_ID/locks/hash.json
`}</code></pre>
    <h4>{`Parameters`}</h4>
    <p>{`This request has no parameters.`}</p>
    <h4>{`Response`}</h4>
    <p>{`Upon success, key-value pairs are returned under a “hashlock” key.`}</p>
    <p>{`Example:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "hashlock": {
    "type": "advanced",
    "url": "https://yourdomain.com/auth/123",
    "message": null,
    "button_caption": null,
    "popup_width": "0",
    "popup_height": "0"
  }
}
`}</code></pre>
    <p>{`Specific error codes returned when the action failed:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`ERROR VALUE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`HTTP RESPONSE CODE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`DESCRIPTION`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`lack_of_ownership`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`403 Forbidden`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The API user is not allowed to manage the given channel`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`not_found`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`404 Not found`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Channel not found`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Removing channel viewer authentication`}</h3>
    <pre><code parentName="pre" {...{}}>{`DELETE  https://api.video.ibm.com/channels/CHANNEL_ID/locks/hash.json
`}</code></pre>
    <h4>{`Parameters`}</h4>
    <p>{`This request has no parameters.`}</p>
    <h4>{`Response`}</h4>
    <p>{`Upon success the 200 OK HTTP response code is returned.`}</p>
    <p>{`Specific error codes returned when the action failed:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`ERROR VALUE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`HTTP RESPONSE CODE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`DESCRIPTION`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`lack_of_ownership`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`403 Forbidden`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The api user is not allowed to manage the given channel`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`not_found`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`404 Not found`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Channel not found`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Getting video viewer authentication settings`}</h3>
    <pre><code parentName="pre" {...{}}>{`GET https://api.video.ibm.com/videos/VIDEO_ID/locks/hash.json
`}</code></pre>
    <h4>{`Parameters`}</h4>
    <p>{`This request has no parameters.`}</p>
    <h4>{`Response`}</h4>
    <p>{`Upon success, key-value pairs are returned under a “hashlock” key.`}</p>
    <Title mdxType="Title">Example</Title>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "hashlock": {
    "url": "https://auth.yourdomain.com/video-authentication",
    "secret": "TOP_SECRET_KEY"
  }
}
`}</code></pre>
    <p>{`Specific error codes returned when the action failed:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`ERROR VALUE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`HTTP RESPONSE CODE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`DESCRIPTION`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`lack_of_ownership`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`403 Forbidden`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The api user is not allowed to manage the given channel`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`not_found`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`404 Not found`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Video or specific authentication settings not found`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Removing specific video viewer authentication settings`}</h3>
    <p>{`If you remove specific settings then video will inherit the authentication settings from the channel.`}</p>
    <pre><code parentName="pre" {...{}}>{`DELETE  https://api.video.ibm.com/videos/VIDEO_ID/locks/hash.json
`}</code></pre>
    <h4>{`Parameters`}</h4>
    <p>{`This request has no parameters.`}</p>
    <h4>{`Response`}</h4>
    <p>{`Upon success the 204 No Content HTTP response code is returned.`}</p>
    <p>{`Specific error codes returned when the action failed:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`ERROR VALUE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`HTTP RESPONSE CODE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`DESCRIPTION`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`lack_of_ownership`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`403 Forbidden`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The api user is not allowed to manage the given channel`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`not_found`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`404 Not found`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Video or specific authentication settings not foun`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      